import { useState, useEffect } from "react";
import "./../assets/css/Navigation.css";

const menu = {
  edu: "Education",
  exp: "Experience",
  awr: "Awards",
};

function Navigation() {
  const [time, setTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const options = {
        timeZone: "Asia/Ashgabat",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat([], options);
      setTime(formatter.format(new Date()));
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <header>
        <nav>
          <ul className="menu">
            {Object.entries(menu).map(([key, value]) => (
              <li key={key}>
                <a href={`#${key}`}>{value}</a>
              </li>
            ))}
          </ul>
          <div className="logo">
            <a href="https://www.geldiyeff.com/">
              <ul>
                <li>Geldiyef</li>
                <li>f</li>
                <li>f</li>
                <li>f</li>
              </ul>
            </a>
          </div>
          <ul className="info">
            <li>
              <span>Ashgabat/Turkmenistan</span>
              <span>{time}</span>
            </li>
            <li>
              <a href="mailto:imgeldiyeff@gmail.com">imgeldiyeff@gmail.com</a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Navigation;
