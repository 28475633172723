import "./../assets/css/Experiences.css";
import Komtas from "./../assets/img/exp/komtas.png";
import KomtasBg from "./../assets/img/exp/komtas-o.png";
import Thy from "./../assets/img/awr/thy.png";

function Experiences() {
  return (
    <>
      <div className="exp" id="exp">
        <div className="content" id="awr">
          <div className="jobs">
            <h1>Experiences</h1>
            <div className="comp">
              <img src={Komtas} alt="Komtas logo" />
              <div className="role">
                <p>Software Engineer - Intern</p>
                <h2>KOMTAŞ Bilgi Yönetimi</h2>
                <div>
                  <span>Aug 2023 - Sep 2023</span>
                  <span>Istanbul / Turkey</span>
                </div>
              </div>
            </div>
          </div>
          <div className="awr">
            <h1>Awards</h1>
            <div className="award">
              <div className="desc">
                <p>May 2023</p>
                <h2>Special Jury Prize</h2>
              </div>
              <img src={Thy} alt="Turkish Airlines logo" />
            </div>
          </div>
        </div>
        <img
          src={KomtasBg}
          alt="Komtas background"
          className="company-backside"
        />
      </div>
    </>
  );
}

export default Experiences;
