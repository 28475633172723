import "./../assets/css/Education.css";
import GaziLogo from "./../assets/img/edu/w-logo.png";

function Education() {
  return (
    <>
      <div className="edu" id="edu">
        <div className="content">
          <h1>Education</h1>
          <div className="uni">
            <img src={GaziLogo} alt="Gazi university logo" />
            <div className="degree">
              <div>
                <p>Gazi University</p>
                <h2>Bachelor's</h2>
              </div>
              <div>
                <p>Faculty of Engineering</p>
                <h2>Computer Engineering</h2>
              </div>
            </div>
          </div>
          <img
            src={GaziLogo}
            alt="Gazi university logo"
            className="backside-logo"
          />
        </div>
      </div>
    </>
  );
}

export default Education;
