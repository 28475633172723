import React from "react";
import "./App.css";

import Navigation from "./components/Navigation";
import Title from "./components/Title";
import Education from "./components/Education";
import Experiences from "./components/Experiences";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Title />
      <Education />
      <Experiences />
    </div>
  );
}

export default App;
