import { useState, useEffect } from "react";
import "./../assets/css/Title.css";

const social = {
    GitHub: "https://github.com/geldiyeff",
    LinkedIn: "https://www.linkedin.com/in/geldiyeff/",
    Instagram: "https://www.instagram.com/dvlt39/",
    Spotify: "https://open.spotify.com/user/31d2xspwzlep5l6ow4fjtlq5xqy4?si=15b06e2a316c43a1"
};

function Title() {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const savedTheme = localStorage.getItem("darkMode");
        if (savedTheme) {
            const isDarkMode = JSON.parse(savedTheme);
            setDarkMode(isDarkMode);
            document.body.classList.toggle("dark-mode", isDarkMode);
        }
    }, []);

    const handleThemeChange = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        document.body.classList.toggle("dark-mode", newDarkMode);
        localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
    };

    return (
        <>
            <div className="title">
                <div className="content">
                    <div className="head">
                        <p>Hello! I'm</p>
                        <p>
                            D
                            <input
                                type="checkbox"
                                id="theme"
                                checked={darkMode}
                                onChange={handleThemeChange}
                            />
                            <label htmlFor="theme">
                                <span className="circle"></span>
                            </label>
                            VLETMAMMET GELDIYEV
                        </p>
                    </div>
                    <div className="body">
                        <p>
                            A software developer with a passion for crafting innovative
                            solutions. With expertise in Java, PHP, and web-based microservice
                            architectures, I specialize in delivering secure, user-centric
                            applications. My approach integrates modern technologies to create
                            seamless and efficient systems.
                        </p>
                        <p>
                            As a continuous learner, I thrive on mastering new skills and
                            refining my craft. From design to code, database structuring to
                            cloud solutions, my technical range covers a broad spectrum of the
                            development process.
                        </p>
                        <p>
                            Explore my portfolio to discover the projects I’ve worked on and
                            the journey I’ve taken in my career.
                        </p>
                        <ul className="social">
                            {Object.entries(social).map(([key, value]) => (
                                <li key={key}>
                                    <a href={value} target="_blank" rel="noreferrer">
                                        {key}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Title;
